<template>
    <modal name="QuoteRequestModal" height="auto" :adaptive="true" :scrollable="true" :clickToClose="true">
    <div class="popup about-us-in no-bg">
        <div class="form-popup">
            <form ref="form" @submit.prevent="sendEmail">
              <div slot="top-right" class="cross-icon">
          <button @click="$modal.hide('QuoteRequestModal')">
            ❌
          </button>
        </div>
                <div class="form-title">
                    <h2>Request a Quote</h2>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="name" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Name</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="company" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Company you represent</label>
                </div>
                <div class="input-wrapper">
                    <input type="email" name="email" class="form-control" required email>
                    <label for="user" class="form-control-placeholder">Contact Email</label>
                </div>
                <div class="input-wrapper">
                    <input type="number" name="number" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Contact Phone number</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="commodity_type" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Commodity type</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="cargo" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Cargo Liability requirement</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="annual_shipment"  class="form-control" required>
                    <label for="user" class="form-control-placeholder">Annual number of shipments</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="hear_abour_us" rclass="form-control" equired>
                    <label for="user" class="form-control-placeholder">How did you hear about us?</label>
                </div>
                <div class="input-wrapper">
                    <input type="text" name="referral" class="form-control" required>
                    <label for="user" class="form-control-placeholder">Referral</label>
                </div>
                <div class="input-wrapper">
                    <textarea name="message" class="form-control" required></textarea>
                    <label for="user" class="form-control-placeholder">Message</label>
                </div>
                <div class="form-group">
                    <input type="submit" class="submit" value="Request quote" :disabled=processing>
                </div>
                <div class="loader">
                  <img src="../assets/images/loader.gif" alt="" :class="{'show' : this.processing}">
                  <div class="success-msg">
                    <p :class="{'show' : this.success}"> Thank you for submission.</p>
                  </div>
                </div>
            </form>
        </div>
    </div>
    </modal>
</template>

<script>
import emailjs from 'emailjs-com';


export default {
  name: 'QuoteRequest',
  data(){
        return {
          errors: [],
          success: false,
          processing: false,
          name: null,
          email: null,
          phone: null,
          contact_phone: null,
          message: null
        }
  },
  methods: {
    sendEmail() {
        this.processing = true;
        emailjs.sendForm('service_3jmtejb', 'template_hfzaydf', this.$refs.form,'EFDDawqYuA7FZA8uP')
          .then(() => {
              this.processing = false;
              this.success = true;
              this.$refs.form.reset();
              setTimeout(() => {
                this.success = true;
              }, 5000)
          }, (error) => {
              this.processing = false;
              this.errors.push(error.response.data.message);
        });
    }
  }
}
</script>